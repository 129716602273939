import { __decorate } from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import SaveFieldBtn from '@/components/SaveField.vue';
import CodeModal from './CodeModal.vue';
import BaseFormMixins from '@/mixins/base-form-mixins';
import { sendCode } from '@/api/auth.js';
import PhoneInput from '@/components/UI/inputs/PhoneInput.vue';
let Mail = class Mail extends BaseFormMixins {
    constructor() {
        super(...arguments);
        this.rules = [];
        this.oldValue = '';
        this.oldValue2 = '';
        this.phoneWithCode = '';
        this.isShowCodeModal = false;
        this.typeCodeModal = 'phone';
        this.phoneSaved = false;
        this.emailSaved = false;
    }
    mounted() {
        this.oldValue = this.email;
        this.oldValue2 = this.phone;
        this.rules.push(this.inputRules.validEmail);
    }
    setPhoneWithCode(newVal) {
        console.log(newVal);
        this.phoneWithCode = newVal.formattedNumber;
        this.phone = newVal.phoneNumber;
    }
    get iconName() {
        return this.checkError(this.$refs.email) ? 'close-icon' : this.email ? 'check-icon' : null;
    }
    get email() {
        return this.$store.state.personalArea.patient.email;
    }
    set email(value) {
        this.$store.commit('personalArea/setPatientProperty', { name: 'email', value });
    }
    get login() {
        const c = {
            email: this.email,
            phone: this.phoneWithCode
        };
        return c[this.typeCodeModal];
    }
    showCodeModal(type) {
        this.isShowCodeModal = true;
        this.typeCodeModal = type;
        this.sendCodeHandler();
        this[`${type}Saved`] = true;
    }
    async sendCodeHandler() {
        const params = {
            [this.typeCodeModal]: this.login,
            reason: `approve_${this.typeCodeModal}`
        };
        const res = await sendCode(params);
    }
    async saveData(code) {
        const res = await this.$store.dispatch('personalArea/updatePatientData', { [this.typeCodeModal]: this.login, code });
        if (res) {
            this.isShowCodeModal = false;
        }
    }
    get phone() {
        return this.$store.state.personalArea.patient.phone;
    }
    set phone(value) {
        this.$store.commit('personalArea/setPatientProperty', { name: 'phone', value });
    }
};
__decorate([
    Prop({ default: true, type: Boolean })
], Mail.prototype, "disableAcceptField", void 0);
__decorate([
    Prop({ default: false, type: Boolean })
], Mail.prototype, "disableField", void 0);
Mail = __decorate([
    Component({
        components: {
            SaveFieldBtn,
            CodeModal,
            PhoneInput
        },
    })
], Mail);
export default Mail;
