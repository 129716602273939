import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import AccessibleCity from '../AccessibleCity.vue';
let LegalInformation = class LegalInformation extends Vue {
    constructor() {
        super(...arguments);
        this.legalInfoList = [
            {
                label: 'Защита данных',
                link: 'https://labstory.app/data-security',
            },
            {
                label: 'Политика конфиденциальности',
                link: 'https://labstory.app/privacy',
            },
            {
                label: 'Пользовательское соглашение',
                link: 'https://labstory.app/agreement',
            },
        ];
    }
};
LegalInformation = __decorate([
    Component({ components: { AccessibleCity } })
], LegalInformation);
export default LegalInformation;
