import { __decorate } from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import BaseFormMixins from '@/mixins/base-form-mixins';
import SaveFieldBtn from '@/components/SaveField.vue';
;
let Name = class Name extends BaseFormMixins {
    constructor() {
        super(...arguments);
        this.oldValue = '';
        this.rules = [];
    }
    mounted() {
        this.rules.push(this.inputRules.required, ...this.propRules);
        this.oldValue = this.name;
    }
    get iconName() {
        return this.checkError(this.$refs.name) ? 'close-icon' : this.name ? 'check-icon' : null;
    }
    get name() {
        return this.$store.state.personalArea.patient?.name;
    }
    set name(value) {
        this.$store.commit('personalArea/setPatientProperty', {
            name: 'name',
            value: this.checkInputValueByRegExp(this.onlyLetters, value),
        });
    }
    validate() {
        return this.$refs.name.validate();
    }
    async saveData() {
        if (!this.validate())
            return;
        await this.$store.dispatch('personalArea/updatePatientData', { name: this.name });
        this.oldValue = this.name;
    }
};
__decorate([
    Prop({ default: () => [] })
], Name.prototype, "propRules", void 0);
__decorate([
    Prop({ default: false, type: Boolean })
], Name.prototype, "disableAcceptField", void 0);
__decorate([
    Prop({ default: false, type: Boolean })
], Name.prototype, "disableField", void 0);
Name = __decorate([
    Component({
        components: {
            SaveFieldBtn,
        },
    })
], Name);
export default Name;
