<template lang="pug">
MessageModal(v-bind="$attrs" v-on="$listeners" size="md" hideOkButton)
  template(#body)
    template(v-if="type === 'email'")
      .weight-medium Мы выслали код на почту
      span Чтобы подтвердить изменение, введите код, который мы выслали на почту {{login}}
    template(v-if="type === 'phone'")
      .weight-medium Мы выслали SMS с кодом
      span Чтобы подтвердить изменение, введите код, который мы выслали на номер {{login}}
    CodeInput(v-model="code" @codeComplete="$emit('codeComplete', code)")

</template>

<script>
import MessageModal from '@/components/modals_new/templates/MessageModal.vue';
import CodeInput from '@/components/UI/inputs/CodeInput.vue';

export default {
  data(){
    return {
      code: false
    }
  },
  components: {MessageModal, CodeInput},
  props: ['type', 'login'],
  }
</script>