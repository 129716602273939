import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import Name from '@/views/PersonalArea/userSetting/Name.vue';
import Mail from '@/views/PersonalArea/userSetting/Mail.vue';
import Surname from '@/views/PersonalArea/userSetting/Surname.vue';
import Patronym from '@/views/PersonalArea/userSetting/Patronym.vue';
import Oms from '@/views/PersonalArea/userSetting/Oms.vue';
import Dms from '@/views/PersonalArea/userSetting/Dms.vue';
import Password from '@/views/PersonalArea/userSetting/Password.vue';
import { IRouter } from '@/interfaces/router.interface';
var ROUTE_NAME = IRouter.ROUTE_NAME;
import BasePageHeader from '@/components/BasePageHeader.vue';
import UserCardAvatar from '@/views/PersonalArea/userCard/UserCardAvatar.vue';
import LegalInformation from '@/views/PersonalArea/userSetting/LegalInformation.vue';
let UserSettings = class UserSettings extends Vue {
    get isProfilePage() {
        return this.$route.name === ROUTE_NAME.PROFILE_PAGE;
    }
    get labstoryPatient() {
        return this.$store.state.personalArea.patient.from_jlab;
    }
};
UserSettings = __decorate([
    Component({
        components: {
            Name,
            Mail,
            Surname,
            Patronym,
            Oms,
            Dms,
            Password,
            BasePageHeader,
            UserCardAvatar,
            LegalInformation,
        },
    })
], UserSettings);
export default UserSettings;
