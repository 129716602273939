import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import BaseFormMixins from '@/mixins/base-form-mixins';
import SaveFieldBtn from '@/components/SaveField.vue';
let Oms = class Oms extends BaseFormMixins {
    constructor() {
        super(...arguments);
        this.rules = [];
        this.oldValue = '';
    }
    mounted() {
        this.rules.push(this.inputRules.maxMinlength);
        this.oldValue = this.oms;
    }
    get oms() {
        return this.$store.state.personalArea.oms;
    }
    set oms(value) {
        this.$store.commit('personalArea/setPropertyInStore', { name: 'oms', value });
    }
};
Oms = __decorate([
    Component({
        components: {
            SaveFieldBtn,
        },
    })
], Oms);
export default Oms;
