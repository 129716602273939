<template lang="pug">
.accessibleCity
  //- icon(name="ls-logo" width="21px" height="17px").accessibleCity-logo
  img(src="@/assets/ls-logo2.svg")
  .accessibleCity-text Где вы можете сдать анализы в ЛабСтори
  BaseBtn(@click="isModalActive = true" size="xs" type="light").accessibleCity-pseudoBtn 
    span {{currentCity.city}}
    icon(name="next-icon" height="8px" width="4px").accessibleCity-pseudoBtnIcon
  BaseBtn(size="sm" :href="currentCity.link").accessibleCity-mapBtn Смотреть на карте
  MessageModal(v-model="isModalActive" size="sm" wideButtons).accessibleCity-modal
    template(#body): .accessibleCity-list
      CheckboxInput(v-for="city in cities" :label="city.city" :value="city === selectedCity" @input="selectedCity = city")
    template(#buttons)
      BaseBtn(@click="applyCity" size="sm") Применить
</template>

<script>
import MessageModal from '@/components/modals_new/templates/MessageModal.vue';
import CheckboxInput from '@/components/UI/inputs/CheckboxInput.vue';
import { getMapAdresses } from '@/api/static-variables.js';

export default {
  components: {
    MessageModal,
    CheckboxInput
  },
  data(){
    return {
      isModalActive: false,
      currentCity: null,
      selectedCity: null,
      cities: []
    }
  },
  async mounted() {
    this.cities = await getMapAdresses()
    this.currentCity = this.cities[0]
    this.selectedCity = this.cities[0]
  },
  methods: {
    applyCity(){
      this.currentCity = this.selectedCity
      this.isModalActive = false
    }
  }

}
</script>

<style lang="scss">
.accessibleCity {
  display: grid;
  align-items: center;
  grid-template-columns: auto auto 1fr;
  gap: 18px 10px;
  background: #FFFFFF;
  box-shadow: 0px 4px 15px rgba(22, 20, 44, 0.06);
  border-radius: 24px;
  padding: 18px;
  margin-top: 20px;
  @include media-down($breakpoint-md) {
    margin-bottom: 50px;
    grid-template-columns: auto 1fr;
  }
}

.accessibleCity-pseudoBtn {
  // width: 100%;
  color: $grey-2 !important;
  &:hover {
    color: white !important;
  }
  @include media-down($breakpoint-md){
    grid-column: 1 / -1;
  }
}

.accessibleCity-pseudoBtnIcon {
  transform-origin: 60% 60%;
  transform: rotate(270deg);
  margin-left: 10px;

}

.accessibleCity-mapBtn {
  grid-column: 1 / 4;
  @include media-down($breakpoint-md){
    grid-column: 1 / -1;
  }

}

.accessibleCity-text {
  font-size: 14px;
  line-height: 18px;
  color: $grey-1;
  width: 160px;
}

.accessibleCity-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  gap: 15px
}

</style>