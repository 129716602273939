import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import BaseFormMixins from '@/mixins/base-form-mixins';
import SaveFieldBtn from '@/components/SaveField.vue';
let Dms = class Dms extends BaseFormMixins {
    constructor() {
        super(...arguments);
        this.rules = [];
        this.oldValue = '';
    }
    mounted() {
        this.rules.push(this.inputRules.maxMinlength);
        this.oldValue = this.dms;
    }
    get dms() {
        return this.$store.state.personalArea.dms;
    }
    set dms(value) {
        this.$store.commit('personalArea/setPropertyInStore', { name: 'dms', value });
    }
};
Dms = __decorate([
    Component({
        components: {
            SaveFieldBtn,
        },
    })
], Dms);
export default Dms;
