import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import PasswordChange from '@/views/PersonalArea/userSetting/PasswordChange.vue';
import { bus } from '@/plugins/bus';
import { IAnalyzes } from '@/interfaces/analyzes.interface';
let Password = class Password extends Vue {
    constructor() {
        super(...arguments);
        this.isShowPasswordChange = false;
    }
    get password() {
        return this.$store.state.personalArea.patient.password;
    }
    mounted() {
        bus.$on(IAnalyzes.BusEvents.PASSWORD_CHANGED, () => this.isShowPasswordChange = false);
    }
    showPasswordChangeBlock() {
        this.isShowPasswordChange = !this.isShowPasswordChange;
    }
    destroyed() {
        bus.$off(IAnalyzes.BusEvents.PASSWORD_CHANGED);
    }
};
Password = __decorate([
    Component({
        components: {
            PasswordChange,
        },
    })
], Password);
export default Password;
