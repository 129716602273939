var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"password-change"},[_c('div',{staticClass:"password-change__subtitle"},[_vm._v("Пароль должен быть не менее 8 символов и содержать заглавные, строчные буквы, цифры")]),(_vm.isCreatePassword)?_c('h3',{staticClass:"password-change__title"},[_vm._v("Придумайте пароль")]):_vm._e(),(!_vm.isCreatePassword)?_c('div',{class:{ 'password-change__input form-input': true, 'form-input--empty': _vm.checkError(_vm.$refs.password) }},[_c('label',{staticClass:"form-label"},[_vm._v("Старый пароль")]),_c('q-input',{attrs:{"rounded":"","placeholder":"Введите пароль","type":_vm.pwdObject.oldPassword ? 'password' : 'text',"borderless":false},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}},[_c('q-icon',{staticClass:"cursor-pointer pwd-icon password-change__icon",attrs:{"name":_vm.pwdObject.oldPassword ? 'visibility_off' : 'visibility'},on:{"click":function($event){return _vm.changePwd('oldPassword')}}})],1)],1):_vm._e(),_c('div',{class:{ 'password-change__input form-input': true, 
  'form-input--empty': _vm.checkError(_vm.$refs.newPassword) }},[_c('label',{staticClass:"form-label"},[_vm._v("Новый пароль")]),_c('q-input',{ref:"newPassword",attrs:{"rounded":"","rules":_vm.rules,"placeholder":"Введите новый пароль","type":_vm.pwdObject.newPassword ? 'password' : 'text',"borderless":false},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}},[_c('q-icon',{staticClass:"cursor-pointer pwd-icon",attrs:{"name":_vm.pwdObject.newPassword ? 'visibility_off' : 'visibility'},on:{"click":function($event){return _vm.changePwd('newPassword')}}}),_c('icon',{class:{
          'form-input__icon': true,
          'form-input__icon-valid': !_vm.checkError(_vm.$refs.newPassword),
          'form-input__icon-invalid': _vm.checkError(_vm.$refs.newPassword),
        },attrs:{"name":_vm.iconNameNewPass}})],1),_c('div',{staticClass:"password-change__meter"},[_c('passwordMeter',{attrs:{"password":_vm.newPassword},on:{"score":_vm.onScore}}),_c('div',{staticClass:"password__strength",class:_vm.newPassword ? _vm.passwordStrength : ''},_vm._l((3),function(index){return _c('span',{key:index})}),0),(_vm.passwordStrengthText[_vm.passwordStrength] && _vm.newPassword)?_c('div',{staticClass:"password-change__meter-desk",domProps:{"innerHTML":_vm._s(_vm.passwordStrengthText[_vm.passwordStrength])}}):_vm._e()],1)],1),_c('div',{class:{ 'password-change__input form-input': true, 'form-input--empty': _vm.checkError(_vm.$refs.repeatPassword) }},[_c('label',{staticClass:"form-label"},[_vm._v("Повторите пароль")]),_c('q-input',{ref:"repeatPassword",attrs:{"rounded":"","rules":[_vm.newPasswordValidate ].concat( _vm.rules),"placeholder":"Повторите пароль","type":_vm.pwdObject.repeatPassword ? 'password' : 'text',"borderless":false},model:{value:(_vm.repeatPassword),callback:function ($$v) {_vm.repeatPassword=$$v},expression:"repeatPassword"}},[_c('q-icon',{staticClass:"cursor-pointer pwd-icon",attrs:{"name":_vm.pwdObject.repeatPassword ? 'visibility_off' : 'visibility'},on:{"click":function($event){return _vm.changePwd('repeatPassword')}}}),_c('icon',{class:{
          'form-input__icon': true,
          'form-input__icon-valid': !_vm.checkError(_vm.$refs.repeatPassword),
          'form-input__icon-invalid': _vm.checkError(_vm.$refs.repeatPassword),
        },attrs:{"name":_vm.iconNameRepeatPass}})],1)],1),(_vm.userSettings)?_c('q-btn',{staticClass:"password-change__btn password-change__btn--flooded",attrs:{"padding":"0px 13px","unelevated":"","disabled":_vm.someError},on:{"click":_vm.changePassword}},[_c('span',{staticClass:"password-change__btn-icon"},[_c('icon',{attrs:{"name":"cloud-icon"}})],1),_c('span',{staticClass:"password-change__btn-text"},[_vm._v("Сохранить новый пароль")])]):_vm._e(),(_vm.isCreatePassword && !_vm.userSettings)?_c('div',{staticClass:"password-change__create-new"},[(_vm.isCreatePassword && !_vm.userSettings)?_c('q-btn',{staticClass:"password-change__create-btn button1 button1--bordered-with-icon",attrs:{"padding":"8px"},on:{"click":_vm.createNewPassword}},[_c('span',{staticClass:"password-change__create-btn-icon icon"},[_c('icon',{attrs:{"name":"exit-icon"}})],1),_c('span',{staticClass:"password-change__create-btn-text"},[_vm._v("Войти")])]):_vm._e(),_c('span',{staticClass:"password-change__create-new-skip",on:{"click":_vm.skipStep}},[_vm._v("Пропустить")])],1):_vm._e(),_c('MessageModal',{attrs:{"title":"Ваш пароль изменен"},on:{"close":_vm.closeComponent},scopedSlots:_vm._u([{key:"body",fn:function(){return [_vm._v(" Не забудьте об этом при следующем входе 😉 ")]},proxy:true}]),model:{value:(_vm.isOpenModalPasswordChanged),callback:function ($$v) {_vm.isOpenModalPasswordChanged=$$v},expression:"isOpenModalPasswordChanged"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }