import { __decorate } from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import passwordMeter from 'vue-simple-password-meter';
import BaseFormMixins from '@/mixins/base-form-mixins';
;
import { IUserCard } from '@/interfaces/personal-area.interface';
import { IRouter } from '@/interfaces/router.interface';
var ROUTE_NAME = IRouter.ROUTE_NAME;
import { bus } from '@/plugins/bus';
import { IAnalyzes } from '@/interfaces/analyzes.interface';
import MessageModal from '@/components/modals_new/templates/MessageModal.vue';
let PasswordChange = class PasswordChange extends BaseFormMixins {
    constructor() {
        super(...arguments);
        this.pwdObject = {
            oldPassword: true,
            newPassword: true,
            repeatPassword: true,
        };
        this.newPassword = '';
        this.repeatPassword = '';
        this.passwordStrength = '';
        this.rules = [];
        this.passwordStrengthStatus = IUserCard.PasswordStrength;
        this.passwordStrengthText = {
            [IUserCard.PasswordStrength
                .RISKY]: `<h6>Слабый пароль</h6><p>Пароль должен быть не менее 8 символов и содержать заглавные и строчные буквы, цифры и специальные символы</p>`,
            [IUserCard.PasswordStrength
                .QUESSABLE]: `<h6>Средний пароль</h6><p>Пароль должен быть не менее 8 символов и содержать заглавные и строчные буквы, цифры и специальные символы</p>`,
            [IUserCard.PasswordStrength
                .WEAK]: `<h6>Средний пароль</h6><p>Пароль должен быть не менее 8 символов и содержать заглавные и строчные буквы, цифры и специальные символы</p>`,
            [IUserCard.PasswordStrength
                .SAFE]: `<h6>Надежный пароль</h6><p>Пароль должен быть не менее 8 символов и содержать заглавные и строчные буквы, цифры и специальные символы</p>`,
            [IUserCard.PasswordStrength
                .SECURE]: `<h6>Надежный пароль</h6><p>Пароль должен быть не менее 8 символов и содержать заглавные и строчные буквы, цифры и специальные символы</p>`,
        };
        this.isOpenModalPasswordChanged = false;
        this.someError = null;
    }
    mounted() {
        this.rules.push(...[this.inputRules.required, this.inputRules.validPassword]);
    }
    skipStep() {
        this.$router.push({ name: ROUTE_NAME.INDEX_PAGE });
    }
    newPasswordValidate(val) {
        return val === this.newPassword || 'Пароли не совпадают';
    }
    get iconNameNewPass() {
        return this.checkError(this.$refs.newPassword) ? 'close-icon' : this.newPassword ? 'check-icon' : null;
    }
    get iconNameRepeatPass() {
        return this.checkError(this.$refs.repeatPassword) ? 'close-icon' : this.repeatPassword ? 'check-icon' : null;
    }
    get password() {
        return this.$store.state.personalArea.patient.password === '*'
            ? ''
            : this.$store.state.personalArea.patient.password;
    }
    set password(value) {
        this.$store.commit('personalArea/setPatientProperty', { name: 'password', value });
    }
    onScore({ strength }) {
        if (Object.keys(this.passwordStrengthText).includes(strength))
            this.passwordStrength = strength;
    }
    changePwd(pwd) {
        this.pwdObject[pwd] = !this.pwdObject[pwd];
    }
    validate() {
        return [this.$refs.newPassword.validate(), this.$refs.repeatPassword.validate()].includes(false);
    }
    createNewPassword() {
        if (this.validate())
            return;
        this.$store
            .dispatch('auth/changePatientsData', {
            changedData: { password: this.newPassword },
        })
            .then((status) => {
            if (status) {
                this.$router.push({ name: ROUTE_NAME.INDEX_PAGE });
            }
        });
    }
    async changePassword() {
        if (this.validate())
            return;
        const data = {
            password: this.newPassword,
        };
        if (!this.isCreatePassword) {
            data['old_password'] = this.password;
        }
        const res = await this.$store.dispatch('auth/changePatientsData', {
            changedData: data,
        });
        if (res) {
            this.isOpenModalPasswordChanged = true;
        }
        else {
            this.clearField();
        }
    }
    closeComponent() {
        bus.$emit(IAnalyzes.BusEvents.PASSWORD_CHANGED);
    }
    clearField() {
        this.newPassword = '';
        this.repeatPassword = '';
    }
    updated() {
        const { password, repeatPassword, newPassword } = this.$refs;
        if (password?.hasError || repeatPassword?.hasError || newPassword?.hasError) {
            this.someError = true;
        }
        else {
            this.someError = false;
        }
    }
};
__decorate([
    Prop({ default: false, type: Boolean })
], PasswordChange.prototype, "isCreatePassword", void 0);
__decorate([
    Prop({ default: false, type: Boolean })
], PasswordChange.prototype, "userSettings", void 0);
PasswordChange = __decorate([
    Component({
        components: {
            passwordMeter,
            MessageModal
        },
    })
], PasswordChange);
export default PasswordChange;
